.app-main-progress .header-wrapper {
    width: 90%;
    max-width: 1648px;
}

.app-main-progress-content-wrapper .header-wrapper {
    width: 90%;
    max-width: 800px;
}

.app-main-progress-content-wrapper .header-wrapper h1 {
    margin: 20px auto 10px;
}

.header-wrapper.align-left h1 {
    margin: 0;
}

.app-main-progress {
    margin: 120px auto 0px;
    width: 90%;
    max-width: 1648px;
    position: relative;
    overflow: hidden;
}

.align-left p {
    max-width: 620px;
}

.app-main-progress-content {
    padding-top: 70px;
}

.app-main-progress img {
    max-width: 100%;
}

.app-main-progress-content-wrapper {
    background: linear-gradient(103.61deg, #39A1E3 6.48%, #70CBFE 95.59%);
    padding: 414px 0px 50px;
    border-radius: 10px;
}

.app-main-progress-content-inner-wrapper {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 56px 0px 80px;
    z-index: 1;
    position: relative;
    backdrop-filter: blur(4px);
    border-radius: 8px;
    width: 96%;
    max-width: 1573px;
    margin: 0 auto;
}

/*.app-main-progress-img {*/
/*    position: relative;*/
/*}*/

.app-main-progress-img > img {
    position: absolute;
    top: 150px;
    left: 100px;
}

.animate {
    animation: fade-in 2s linear 1;
}

.show {
    opacity: 0;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.app-main-progress-loop {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
}

.app-main-progress-item {
    background-color: #e9e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
    align-items: flex-start;
    flex-direction: column;
    transition: 1s;
    position: relative;
    box-shadow: 0px 12px 14px 0px rgba(0, 0, 0, 0.25);
    margin: 15px 15px 25px;
    border-radius: 12px;
    align-items: center;
}

.app-main-progress-item > div {
    max-width: 80%;
}

.app-main-progress-item:hover {
    transform: scale(1.2);
    z-index: 10; /* Увеличиваем масштаб */
}

.app-main-progress-item p:first-of-type {
    margin-top: 16px;
}

.app-main-progress-content .header-wrapper + div {
    display: flex;
}

.p2, .p4 {
    width: 25%;
    max-width: 260px;
    height: 310px;
    z-index: 2;
    margin-left: -20px;
    margin-right: -20px;
}

.p3 {
    width: 34%;
    max-width: 338px;
    height: 352px;
    z-index: 3;
}

.p1, .p5 {
    width: 20%;
    max-width: 210px;
    height: 267px;
    z-index: 1;
}

.p1 img, .p5 img {
    height: 32px;
}

.app-main-progress-item p {
    line-height: 14px;
    width: 100%;
}

.app-main-progress .page-description {
    margin: 19px 0;
}

@media all and (max-width: 1024px) {
    .p1, .p2, .p3, .p4, .p5 {
        height: 337px;
        width: 100%;
    }

    .app-main-progress-loop {
        display: block;
        margin-bottom: 100px;
    }

    .app-main-progress-item:hover {
        transform: none;
        z-index: 0; /* Увеличиваем масштаб */
    }

    .app-main-progress .header-wrapper {
        padding-left: 0px;
    }

    .app-main-progress {
        margin: 60px auto 0px;
    }

    .app-main-progress .page-description {
        width: 90%;
    }

    .app-main-progress .app-main-progress-content .page-description {
        width: 60%;
        margin: 19px auto;
    }

    .app-main-progress .header-wrapper {
        width: 100%;
    }

    .p2, .p4 {
        margin-left: 0px;
    }

    .app-main-progress-item {
        width: 96%;
        margin: 20px auto;
    }

    .p1, .p5 {
        max-width: 100%;
    }

    .app-main-progress-content-wrapper {
        padding: 114px 0px 50px;
    }

    .app-main-progress-img>img {
        top: 200px;
        width: 800px;
        max-width: initial;
    }

    .app-main-progress-content-inner-wrapper {
         border-radius: 0px;
         width: 100%;
    }
}