.checkbox-wrapper .mob-slider-container .slick-dots {
    bottom: -60px;
    max-width: 230px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.mob-slider-container {
    margin-bottom: 80px;
}