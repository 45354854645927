.burger-checkbox {
    position: absolute;
    visibility: hidden;
}
.burger {
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: block;
    position: relative;
    border: none;
    background: transparent;
    width: 17px;
    height: 7px;
}
.burger::before,
.burger::after {
    content: '';
    left: 5px;
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background: #000;
}
.burger::before {
    top: 0;
    transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
}
.burger::after {
    bottom: 0;
    transition: bottom .3s .15s, transform .3s;
}
.burger-checkbox:checked + .burger::before {
    top: 1px;
    transform: rotate(45deg);
    box-shadow: 0 6px 0 rgba(0,0,0,0);
    transition: box-shadow .15s, top .3s, transform .3s .15s;
    left: 5px;
    top: 10px
}
.burger-checkbox:checked + .burger::after {
    bottom: 4px;
    transform: rotate(-45deg);
    transition: bottom .3s, transform .3s .15s;
    left: 5px;
    top: 10px;
}

.menu {
    margin-right: 13px;
    z-index: 101;
    width: 90%;
    height: 15px;
}

.menu .icon-container img{
    width: 100%;
    max-width: 155px;
}

.menu-list {
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 0px 53px 13px;
    margin: 0;
    background: white;
    list-style-type: none;
    transform: translateX(-100%);
    transition: .3s;
    width: 100%;
    border-radius: 4px;
}

.menu-item {
    display: block;
    padding: 16px 0px 16px 4%;
    font-size: 21px;
    text-align: left;
    text-decoration: none;
    font-weight: 500;
}

.menu-item:hover {
    background: rgba(255,255,255,.2);
    color: #4CACE9;
}

.burger-checkbox:checked ~ .menu-list {
    transform: translateX(-50%);
    left: 50%;
}

.menu-list .transparent-btn {
    max-width: 320px;
    width: 90%;
    height: 45px;
    border-radius: 2px;
    border: 1px solid #B2B2B3;
    max-width: 320px;
    margin: 11px 0px 33px 4%;
}

.menu-list a.transparent-btn {
    justify-content: center;
    align-items: center;
}

.menu-list a.transparent-btn img{
    margin-bottom: 0;
    margin-right: 5px;
}