.checkbox-wrapper input {
    display: none;
}

.checkbox-wrapper label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    height: 100%;
}

.checkbox-wrapper label > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox-wrapper label span {
    font-weight: 500;
    padding-left: 5px;
}

.checkbox-wrapper {
    max-width: 1028px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    height: 60px;
    padding-left: 81px;
    border-bottom: 1px solid rgba(46, 55, 60, 0.4);
    position: relative;
    justify-content: center;
}

.checkbox-wrapper .text-wrapper div,
.pan2-wrap span, .pan3-wrap span,
.checkbox-wrapper .slider-container span {
    color: #898989;
    padding: 10px 5px;
    font-size: 13px;
}

.checkbox-item {
    margin-right: 10px;
}

.checkbox-item>div {
    height: 100%;
}

.checkbox-inActive label,
.checkbox-inActive img {
    color: rgba(46, 55, 60, 0.6);
    opacity: 0.6;
}

.checkbox-active {
    height: 100%;
    border-bottom: 2px solid rgba(76, 172, 233, 1);
}

.checkbox-img {
    margin-top: 80px;
    width: 100%;
}

.checkbox-img > div {
    position: relative;
    margin: 0 auto 200px;
}

.checkbox-img > div > img {
    width: 100%;
}

.checkbox-active label span {
    color: rgba(76, 172, 233, 1);
}

.checkbox-img.inActive {
    display: none;
}

.checkbox-img.active {
    position: absolute;
    top: 10px;
    left: 0;
}

@media all and (max-width: 1200px) {
    .checkbox-wrapper {
        padding-left: 0px;
        justify-content: space-between;
    }
}

@media all and (max-width: 1024px) {
    .checkbox-wrapper {
        height: 100%;
    }

    .checkbox-wrapper {
        border-bottom: none;
        display: block;
        padding-left: 0px;
        width: 100%;
        padding-bottom: 20px;
    }

    .checkbox-img > div:first-of-type {
        margin: 0 auto 180px;
    }

    .checkbox-img > div:last-of-type {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .app-main-solutions .blue-btn {
        margin: 0;
    }

    .checkbox-img > div > img {
        max-width: 240px;
        padding-top: 50px;
    }

    .checkbox-item {
        margin-right: 0px;
        margin-top: 30px;
    }

    .checkbox-item img {
        color: red;
    }

    .checkbox-active {
        border-bottom: none;
    }

    .checkbox-item label {
        border-bottom: 1px solid rgba(229, 231, 229, 1);
    }

    .app-main-solutions .checkbox-img.active {
        margin-top: 0px;
    }

    .checkbox-img.active {
        position: relative;
        top: 0px;
    }

    .checkbox-img img {
        padding-bottom: 0px;
    }

    .checkbox-wrapper label {
        justify-content: space-between;
        padding-bottom: 15px;
        align-items: center;
        position: relative;
        width: 100%;
    }

    .app-main-solutions .checkbox-wrapper label {
        width: 90%;
        margin: 0 auto;
    }

    .checkbox-inActive label,
    .checkbox-inActive img {
        color: rgba(46, 55, 60, 1);
    }

    .checkbox-active label * {
        color: rgba(76, 172, 233, 1);
    }

    .checkbox-active label::after {
        content: '';
        width: 80%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -1px;
        background: rgba(76, 172, 233, 1);
    }

    .checkbox label span {
        text-align: left;
    }
}

@media all and (max-width: 768px) {
    .checkbox-img > div:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
