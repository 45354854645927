.app-main-solutions {
    background-color: white;
    letter-spacing: -0.06em;
    text-align: center;
    padding-top: 50px;
    border-radius: 10px;
    padding-bottom: 635px;
    margin: 0 auto 24px;
    width: 90%;
    max-width: 1648px;
}

.app-main-solutions .checkbox-img>div:first-of-type {
    width: 601px;
}

.app-main-solutions .header-wrapper {
    max-width: 877px;
}

.page-description img {
    height: 35px;
    margin-left: 10px;
    margin-top: 10px;
}

.app-main-solutions .s1 {
    width: 187px;
}

.app-main-solutions .s2 {
    width: 183px;
}

.app-main-solutions .s3 {
    width: 247px;
}

.app-main-solutions .transparent-btn {
    border: 1px solid rgba(76, 172, 233, 1);
    color: rgba(76, 172, 233, 1)
}

svg {
    fill: #2E373C99;
}

input:checked + label svg {
    fill: #4CACE9;
}

.s2 svg {
    fill: none;
    stroke: #2E373C99;
}

input#s2:checked + label svg {
    fill: none;
    stroke: #4CACE9;
}

.checkbox-item:hover svg {
    fill: #4CACE9;
}

.checkbox-item:hover label span {
    color: #4CACE9;
}

.checkbox-item.s2:hover svg {
    fill: none;
    stroke: #4CACE9;
}

.app-main-solutions .transparent-btn:hover {
    border: 1px solid #D9D9D9;
    background-color: #EBECEB;
}

.s11>div, .s12>div, .s13>div, .s14>div,
.s15>div, .s25>div, .s32>div, .s35>div,
.s41>div, .s42>div, .s43>div {
    height: 53px;
}

.s21>div, .s22>div, .s23>div, .s24>div,
.s31>div, .s33>div, .s44>div {
    height: 78px;
}

.s11 {
    bottom: -50px;
    left: 25px;
}

.s11>div {
    bottom: -60px;
    left: -70px;
    width: 100px;
}

.s12 {
    bottom: -60px;
    left: 60px;
}

.s12>div {
    bottom: -25px;
    width: 100px;
    left: 25px;
}

.s13 {
    bottom: -35px;
    left: 130px;
}

.s13>div {
    width: 230px;
    left: 70px;
    bottom: -55px;
}

.s14 {
    bottom: -100px;
    left: 150px;
}

.s14>div {
    width: 250px;
    left: 30px;
    bottom: -55px;
}

.s15 {
    bottom: -40px;
    right: 50px;
}

.s15>div {
    width: 170px;
    bottom: -55px;
    left: -90px;
}

.s21 {
    bottom: -20px;
    left: 60px;
}

.s21>div {
    width: 190px;
    bottom: -80px;
    left: -100px;
}

.s22 {
    bottom: -30px;
    left: 150px;
}

.s22>div {
    width: 190px;
    left: 15px;
    bottom: -70px;
}

.s23 {
    bottom: -110px;
    right: 40px;
}

.s23>div {
    bottom: -75px;
    left: -240px;
    width: 240px;
}


.s24 {
    bottom: -18px;
    left: 275px;
}

.s24>div {
    width: 193px;
    bottom: -80px;
    right: -180px;
}

.s25 {
    right: 30px;
    top: 170px;
}

.s25>div {
    width: 220px;
    right: -50px;
    bottom: -30px;
}

.s31 {
    top: 85px;
    left: 65px;
}

.s31>div {
    width: 200px;
    bottom: -80px;
    left: -110px;
}

.s32 {
    bottom: -20px;
    left: 150px;
}

.s32>div {
    width: 160px;
    bottom: -50px;
    left: 10px;
}

.s33 {
    top: 100px;
    left: 270px;
}

.s33>div {
    width: 190px;
    bottom: -70px;
    left: 70px;
}

.s34 {
    right: 60px;
    bottom: -115px;
}

.s34>div {
    bottom: -55px;
    width: 220px;
    left: -220px;
}

.s35 {
    right: 40px;
    top: 160px;
}

.s35>div {
    width: 240px;
    bottom: -40px;
    left: -140px;
}

.s41 {
    bottom: -50px;
    left: 40px;
}

.s41>div {
    width: 170px;
    bottom: -55px;
    left: 10px;
}

.s42 {
    top: 90px;
    right: 90px;
}

.s42>div {
    width: 220px;
    right: -20px;
    bottom: -55px;
}

.s43 {
    top: 40px;
    right: 50px;
}

.s43>div {
    width: 226px;
    bottom: -55px;
    left: -180px;
}

@media all and (max-width: 1024px) {
    .app-main-solutions .checkbox-img>div:first-of-type {
        width: fit-content;
    }

    .app-main-solutions .page-description{
        max-width: 200px;
    }

    .app-main-solutions .header-wrapper h1 {
        font-size: 28px;
        line-height: 32px;
    }

    .app-main-solutions .s1,
    .app-main-solutions .s2,
    .app-main-solutions .s3 {
        width: 100%;
    }

    .s11 {
        bottom: -18px;
        left: 200px;
    }

    .s11>div {
        bottom: -20px;
        left: -120px;
        width: 120px;
    }

    .s12 {
        top: 85px;
        left: 35px;
    }

    .s12>div {
        width: 103px;
        bottom: 30px;
        left: -30px;
    }

    .s13 {
        bottom: -55px;
        left: 35px;
    }

    .s13>div {
        left: -30px;
    }

    .s14 {
        bottom: 115px;
    }

    .s14>div {
        width: 180px;
        bottom: -15px;
        left: -140px;
    }

    .s15 {
        top: 75px;
        right: -30px;
    }

    .s15>div {
        width: 173px;
        bottom: 105px;
        left: -120px;
    }

    .s12 {
        top: 135px;
        left: 35px;
    }

    .s21 {
        top: 100px;
        left: 15px;
    }

    .s21>div {
        top: -80px;
        left: -50px;
    }

    .s22 {
        top: 230px;
        left: 10px;
    }

    .s22>div {
        display: flex;
        top: 290px;
        width: 220px;
        left: 20px;
        height: fit-content;
    }

    .s23 {
        top: 300px;
        left: 60px;
    }

    .s23>div {
        left: 10px;
        bottom: -25px;
        width: 210px;
        height: fit-content;
    }

    .s24 {
        top: 330px;
        left: 210px;
    }

    .s24>div {
        top: 260px;
        left: -200px;
        width: 264px;
        height: fit-content;
    }

    .s41 {
        bottom: -90px;
    }

    .s41>div {
        bottom: -25px;
    }

    .s42 {
        top: 435px;
        right: 165px;
    }

    .s42>div {
        width: 170px;
        right: -172px;
        bottom: -25px;
    }

    .app-main-solutions {
        text-align: left;
    }

    .app-main-solutions {
        padding-bottom: 35px;
    }
}
