.s31-wrap, .s32-wrap {
    position: relative;
    height: 550px;
    width: fit-content;
    margin: 0 auto;
}

.s31-wrap > img, .s32-wrap > img {
    height: 384px;
    margin: 0 auto;
}

.s31-wrap > div, .s32-wrap > div {
    position: absolute;
}

.s31-wrap span, .s32-wrap span {
    background: rgba(238, 238, 238, 0.6);
    display: inline-block;
    border-radius: 8px;
    padding: 10px;
    backdrop-filter: blur(9px);
    box-shadow: 0px 8px 19px 0px rgba(0, 45, 97, 0.25);
    position: absolute;
    font-size: 13px;
    line-height: 15px;
}

.s31-inn {
    top: 40px;
    left: 10px;
}

.s31-inn span {
    width: 213px;
    top: -85px;
    left: -50px;
}

.app-main-solutions .slick-slide {
    padding-top: 60px;
}

.s32-inn {
    top: 90px;
}

.s32-inn span {
    width: 200px;
    left: -20px;
}

.s33-inn {
    left: 100px;
    bottom: 120px;
}

.s33-inn span {
    top: 30px;
    width: 140px;
    left: 15px;
}

.s34-inn {
    top: 25px;
    left: 20px;
}

.s34-inn span{
    width: 200px;
    top: -70px;
    left: -30px;
}

.s35-inn {
    top: 100px;
}

.s35-inn span {
    width: 250px;
}