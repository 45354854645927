.app-main-contacts {
    background: url('../images/bg5-1920.png') no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin: 50px auto;
    width: 90%;
    max-width: 1648px;
    padding: 6% 80px 20px;
}

.app-main-contacts-container {
    margin-left: 70px;
    padding-bottom: 37px;
    margin-top: 5%;
}

.app-main-contacts-container-info {
    display: flex;
    max-width: 826px;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 5%;
    flex-wrap: wrap;
}

.app-main-contacts-container-info h2 {
    font-size: 21px;
    font-weight: 500;
    line-height: 21px;
}

.app-main-contacts-container-info-item p {
    display: block;
    padding-left: 10px;
}

.app-main-contacts-container-info-item div{
    display: flex;
    margin-top: 13px;
}

.app-main-contacts-container-card {
    max-width: 869px;
    width: 65%;
    height: 312px;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-main-contacts-container-card iframe {
    max-width: 815px;
    width: 100%;
    height: 247px;
}

@media all and (max-width: 1400px) {
    .app-main-contacts {
        padding: 2% 80px 20px;
    }
}

@media all and (max-width: 1200px) {
    .app-main-contacts-container {
        margin: 0px 20px;
        display: flex;
    }

    .app-main-contacts {
        padding: 6% 20px 20px;
    }

    .app-main-contacts-container-info {
        width: 60%;
        flex-direction: column;
    }

    .app-main-contacts-container-card {
        width: 59%;
    }
}

@media all and (max-width: 768px) {
    .app-main-contacts {
        background: url('../images/bg5-mob.png') no-repeat;
        background-size: cover;
    }

    .app-main-contacts {
        padding-bottom: 30px;
    }

    .app-main-contacts-container {
        margin: 0px;
        margin-top: 23px;
        padding: 0px 20px;
        flex-direction: column;
    }

    .app-main-contacts-container-info {
        width: 100%;
    }

    .app-main-contacts-container-card {
        width: 100%;
    }

    .app-main-contacts-container-info {
        margin-bottom: 0px;
    }

    .app-main-contacts-container-info h2 {
        font-size: 18px;
    }

    .app-main-contacts-container-info-item {
        padding-bottom: 31px;
    }

    .app-main-contacts-container-card {
        width: 100%;
        height: 195px;
        margin-top: 10px;
    }

    .app-main-contacts-container-card iframe {
        width: 90%;
        height: 165px;
    }
}