.slider-dots {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.app-main .slider-dots-wrapper {
    bottom: -40px;
    width: 150px;
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    height: 60px;
    display: flex;
    margin: 0 auto;
    background: #F5F5F5;
}

.app-main .slick-dots {
    bottom: -80px;
    width: 90%;
}

@media all and (max-width: 1024px) {
    .app-main .slick-dots {
        width: 100%;
    }
}