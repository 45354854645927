.app-subscription-wrapper {
    width: 90%;
    margin: 50px auto 0px;
    max-width: 1396px;
    background: rgba(245, 245, 247, 1);
    border-radius: 10px;
    position: relative;
    padding: 121px 0px 151px;
    min-height: 735px;
}

.app-subscription input {
    display: none;
}

.app-subscription label {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    padding-bottom: 10px;
    position: relative;
}

.app-subscription label div{
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 10px;
}

.app-subscription-item-wrapper {
    z-index: 1;
    position: relative;
    max-width: 1059px;
    margin: 0 auto;
    width: 90%;
}

.app-subscription-item {
    border-bottom: 1px solid rgba(229, 231, 229, 1);
    margin-bottom: 36px;
    width: 50%;
    max-width: 520px;
}

.app-subscription-item-inActive img {
    color: rgba(46, 55, 60, 0.6);
    opacity: 0.6;
    height: 9px;
}

.app-subscription-item-inActive label {
    color: rgba(46, 55, 60, 0.6);
    opacity: 0.6;
}

.app-subscription-item-active {
    height: 100%;
    border-bottom: 2px solid rgba(46, 55, 60, 1);
}

.app-subscription-img-inActive {
    display: none;
    width: 100%;
}

.app-subscription-img-active {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 550px;
}

.app-subscription-item.active label::after {
    content: '';
    width: 212px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -1px;
    background: rgba(76, 172, 233, 1);
}

.app-subscription-item.inActive+div {
    display: none;
}

/*.app-subscription-item.active+div div{*/
/*    width: 90%;*/
/*    max-width: 462px;*/
/*}*/

.app-subscription-item.active+div>div{
    width: 50%;
    max-width: 450px;
    margin-left: 70px;
}

.app-subscription-item.active+div div img{
    margin-right: 20px;
}

.app-subscription-item.active+div span{
    font-size: 21px;
    line-height: 40px;
}

.app-subscription-item.active+div p {
    font-size: 18px;
    margin-left: 40px;
    margin-top: -10px;
    color: rgba(185, 185, 185, 1);
}

.app-subscription-img {
    position: absolute;
    width: 30%;
    top: -25px;
    right: 0%;
    z-index: 0;
    max-width: 289px;
}

.app-subscription-item-wrapper button {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 36px;
    max-width: 286px;
}

@media all and (max-width: 1024px) {
    .app-subscription-img {
        position: relative;
    }

    .app-subscription {
        padding-top: 50px;
    }

    .app-subscription-wrapper {
        width: 90%;
        padding: 36px 21px 54px 13px;
    }

    .app-subscription-item {
        width: 100%;
        max-width: 100%;
    }

    .app-subscription-item.active+div span {
        font-size: 16px;
        line-height: 30px;
    }

    .app-subscription-item.active+div p {
        font-size: 16px;
        line-height: 20px;
        padding-top: 5px;
    }

    .app-subscription-item.active+div>div {
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
    }
}