@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

* {
    font-family: Ubuntu;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: rgba(46, 55, 60, 1);
    border: none;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 18px;
}

svg {
    width: 30px;
}

p {
    margin: 0;
}

h1, h2, h3 {
    margin: 0;
    font-weight: 400;
}

body {
    background: rgba(235, 236, 235, 1);
}

button {
    border-radius: 4px;
    cursor: pointer;
}

.app {
    padding-top: 40px;
    margin: 0 auto;
}

.app-header {
    display: flex;
    font-weight: 500;
    position: fixed;
    padding-top: 26px;
    height: 100px;
    z-index: 100;
    left: 50%;
    top: 0px;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba(255, 255, 255);
}

.app-header-tab-phone {
    width: 186px;
    height: 49px;
    gap: 10px;
    border-radius: 2px;
    border: 1px;
    background-color: #FFFFFF;
    font-size: 14px;
    padding: 6px;
}

.app-header-wrapper {
    width: 90%;
    max-width: 1648px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.text-wrapper {
    position: absolute;
    height: fit-content;
    z-index: 2;
}

.text-wrapper > div, .pan2-wrap span, .pan3-wrap span,
.pan4-wrap span, .pan5-wrap span {
    position: absolute;
    border-radius: 8px;
    background: rgba(238, 238, 238, 0.6);
    color: #2E373C;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    line-height: 16px;
    font-size: 14px;
    backdrop-filter: blur(9px);
    text-align: left;
    box-shadow: 0px 8px 19px 0px rgba(0, 45, 97, 0.25);
}

.icon-container img {
    width: 100%;
    border-radius: 10px;
    height: 54px;
}

.app-header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-left: 3%;
}

.app-header-container-menu {
    display: flex;
    width: 430px;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
}

.app-header-container-menu > div {
    height: 24px;
}

.app-header-container-menu > div:hover {
    color: #4CACE9;
    border-bottom: 1px solid #4CACE9;
}

.app-header a {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app-header-container-phone:hover span{
    color: #4CACE9;
}

.app-header-container-phone:hover svg {
    fill:  #4CACE9;
}

.app-header-container-menu-divider {
    height: 12px;
    width: 1px;
    background: rgba(46, 55, 60, 1);
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
}

.app-header-container-contacts {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.app-header-container-contacts svg {
    fill: rgba(46, 55, 60, 1);
}

.app-header-container-contacts .transparent-btn {
    width: 175px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid rgba(46, 55, 60, 1);
}

.app-header-container-contacts .transparent-btn:hover{
    border: 1px solid #4CACE9;
}

.app-header-container-contacts .transparent-btn:hover p{
    color: #4CACE9;
}

.app-header-container-contacts .transparent-btn p {
    text-align: left;
    padding-left: 10px;
}

.app-header-container-phone img {
    height: 24px;
    margin-right: 15px;
}

.app-header-logo-wrapper {
    display: flex;
    height: 40px;
    align-items: center;
}

.app-header-logo-wrapper img {
    height: 40px;
}

.app-header-logo-wrapper span {
    font-size: 24px;
    font-weight: 700;
    margin-left: 5px;
}

button {
    height: 58px;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: rgba(76, 172, 233, 1);
    cursor: pointer;
}

.white-btn {
    border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 1);
    width: 263px;
}

.white-btn:hover {
    background: #FFFFFFCC;
}

.blue-btn {
    margin: 0 auto;
    width: 263px;
    background: rgba(76, 172, 233, 1);
    color: white;
}

.blue-btn:hover {
    background: #359EE1;
}

.transparent-btn {
    border: 1px solid white;
    width: 236px;
    background: transparent;
    margin-left: 9px;
    color: white;
}

.transparent-btn:hover {
    background: #EBECEB;
    color: #2E373C;
}

.header-wrapper h1 {
    font-size: 60px;
    line-height: 59px;
    letter-spacing: -0.06em;
    text-align: center;
    margin: 10px auto;
}

.page-description {
    font-size: 24px;
    line-height: 28px;

    letter-spacing: -0.06em;
    text-align: center;
}

.page-description:last-of-type {
    padding-bottom: 30px;
}

.page-description:first-of-type {
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.header-wrapper {
    margin: 0 auto;
}

.align-left p, .align-left h1 {
    text-align: left;
}

.app-main-footer {
    width: 84%;
    max-width: 1598px;
    margin: 30px auto;
}

.app-main-footer-logo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.app-main-footer-logo img {
    height: 33px;
    opacity: 0.4;
    mix-blend-mode: luminosity;
}

.app-main-footer-logo div {
    padding-left: 35px;
    color: rgba(115, 115, 115, 0.6);
    font-size: 13px;
    line-height: 15px;
}

.footer {
    display: flex;
}

.footer > div {
    display: flex;
    flex-direction: column;
}

.footer span {
    font-size: 13px;
    line-height: 24px;
    color: #898989;
}

.app-main {
    scroll-padding-top: 120px;
}

.footer p, .footer a {
    font-size: 16px;
    line-height: 29px;
    color: #2E373C;
    cursor: pointer;
    text-decoration: none;
}

.footer p:hover, .footer a:hover {
    text-decoration: underline;
}

.footer {
    margin-bottom: 38px;
}

.footer-contact {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
}

.footer-contact > span {
    color: #737373;
    font-size: 13px;
    line-height: 14.95px;
}

.icon-wrapper {
    display: flex;
    width: 38%;
    max-width: 444px;
}

.icon-wrapper a {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.icon-wrapper a span {
    margin-left: 5px;
    font-size: 21px;
    line-height: 39px;
    color: #75767C;
}

.icon-wrapper a:hover span {
    color: #2E373C;
}

.icon-container {
    box-shadow: 0px 12px 14px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.icon-container:hover {
    transform: scale(1.1);
    transition: 1s;
}

.phone-wrapper a {
    text-decoration: none;
    display: flex;
    align-items: flex-end;
}

.phone-wrapper img {
    height: 21px;
}

.phone-wrapper span {
    font-size: 21px;
    font-weight: 700;
    line-height: 27.93px;
    letter-spacing: -0.02em;
    height: 24px;
}

.app-main-footer-description p, .app-main-footer-description span {
    color: rgba(115, 115, 115, 0.6);
    font-size: 13px;
    line-height: 15px;
}

.app-main-footer-description {
    text-align: right;
    padding-bottom: 326px;
}

.app-main-footer-description img {
    height: 15px;
}

@media all and (max-width: 1650px) {
    .app-header-container-contacts {
        width: 62%;
    }

    .app-header-container {
        margin-left: 0%;
    }

    .app-header-container-phone img {
        margin: 0 5px;
    }
}

@media all and (max-width: 1450px) {
    .app-header-container-contacts {
        justify-content: space-evenly;
    }
}

@media all and (max-width: 1200px) {
    .app-header .burger-checkbox:checked ~ ul ~ div a {
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

@media all and (max-width: 1024px) {
    .app-header-container {
        justify-content: flex-end;
    }

    .transparent-btn, .white-btn, .blue-btn {
        max-width: 286px;
        width: 100%;
    }

    .page-description {
        font-size: 21px;
        width: 50%;
        margin: 0 auto;
        line-height: 24px;
    }

    .app-main-footer {
        justify-content: center;
        margin-top: 10px;
    }

    .app-main-footer-logo {
        flex-direction: column;
        justify-content: space-between;
    }

    .app-main-footer-logo div {
        width: 100%;
        flex-direction: column;
        padding-left: 0px;
    }

    .header-wrapper h1 {
        font-size: 28px;
        line-height: 32px;
    }

    .page-description {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .text-wrapper > div, .pan2-wrap span, .pan3-wrap span {
        font-size: 13px;
    }

    .footer {
        margin-top: 30px;
    }

    .hidden, .footer input {
        display: none;
    }

    .footer input:checked + label + div {
        display: flex;
    }

    .footer label {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #2E373C;
        height: 40px;
        border-bottom: 1px solid #E5E7E5;
    }

    .footer label:after {
        content: "";
        position: absolute;
        right: 20px;
        top: 15px;
        background-image: url("../images/arrowDown.svg");
        width: 18px;
        height: 9px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .footer input:checked + label:after {
        content: "";
        background-image: url("../images/arrowUp.svg");
    }
}

@media all and (max-width: 786px) {
    .transparent-btn, .white-btn, .blue-btn {
        margin: 10px auto 10px;
    }

    .page-description {
        width: 100%;
    }

    .page-description:first-of-type {
        padding-top: 0px;
    }

    .page-description:last-of-type {
        padding-bottom: 0px;
        flex-direction: column;
    }

    .footer-contact {
        flex-direction: column;
    }

    .footer-contact .icon-wrapper {
        width: 100%;
        margin-top: 16px;
    }

    .footer-contact img{
        height: 26px;
        margin-bottom: 0px;
    }

    .footer-contact .icon-wrapper a{
        justify-content: flex-start;
    }

    .footer a, .footer p {
        font-size: 13px;
    }

    .app {
        padding-top: 0px;
        margin: 0 auto;
    }

    .app-header {
        padding-top: 6px;
        height: 50px;
    }
}
