.app-main-header {
    background: url("../images/App/bg1-1920.png") no-repeat;
    background-size: cover;
    letter-spacing: -0.06em;
    text-align: center;
    position: relative;
    border-radius: 10px;
    margin: 0 auto 24px;
    width: 92%;
    max-width: 1648px;
}

.app-main-header img:first-of-type {
    position: absolute;
    top: 121px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    max-width: 965px;
}

.app-main-header img:nth-of-type(2) {
    position: absolute;
    top: 87px;
    left: 52%;
    transform: translateX(-50%);
    z-index: 30;
    width: 40%;
    max-width: 495px;
}

.app-main-header-content h1 {
    font-size: 100px;
    padding-top: 580px;
    color: white;
    line-height: 90px;
    position: relative;
    z-index: 10;
}

.app-main-header-content h2 {
    font-size: 60px;
    padding-bottom: 10px;
    color: white;
    line-height: 70px;
}

.app-main-header-content h3 {
    font-size: 24px;
    line-height: 24px;
}

.app-main-header-btns {
    margin-top: 18px;
    padding-bottom: 60px;
}

@media all and (max-width: 1400px) {
    .app-main-header img:first-of-type {
        width: 100%;
    }

    .app-main-header img:nth-of-type(2) {
        width: 100%;
    }
}

@media all and (max-width: 1400px) {
    .app-main-header img:first-of-type {
        width: 100%;
    }

    .app-main-header img:nth-of-type(2) {
        width: 56%;
    }
}

@media all and (max-width: 800px) {
    .app-main-header img:first-of-type {
        width: 100%;
    }

    .app-main-header img:nth-of-type(2) {
        width: 56%;
    }
}

@media all and (max-width: 768px) {
    /*.app-main-header {*/
    /*    max-width: 375px;*/
    /*}*/

    .app-main-header img:first-of-type {
        width: 100%;
        max-width: 375px;
    }

    .app-main-header img:nth-of-type(2) {
        width: 100%;
        max-width: 240px;
    }

    .app-main-header-content h1 {
        font-size: 45px;
        padding-top: 350px;
        line-height: 60px;
    }

    .app-main-header-content h2 {
        font-size: 21px;
        line-height: 28px;
    }

    .app-main-header-content h3 {
        font-size: 14px;
    }

    .app-main-header-btns {
        display: flex;
        flex-direction: column;
    }
}

