.app-main-details {
    padding: 56px 0px 191px 0px;
    margin: 0 auto;
    width: 90%;
    max-width: 1300px;
}

.app-main-details input {
    display: none;
}

.app-main-details label {
    display: flex;
    align-items: flex-start;
    margin-left: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.app-main-details label div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.app-main-details-wrapper {
    position: relative;
    margin-top: 100px;
}

.app-main-details-item {
    height: 59px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(200, 200, 200, 1);
    width: 100%;
    position: relative;
}

.app-main-details-item > div {
    display: flex;
    width: 100%;
    height: 100%;
}

.app-main-details-inActive img {
    color: rgba(46, 55, 60, 0.6);
    opacity: 0.6;
    height: 9px;
}

.app-main-details-inActive label {
    color: rgba(46, 55, 60, 0.6);
    opacity: 0.6;
}

.app-main-details-img-inActive {
    display: none;
    width: 100%;
}

.app-main-details-img-active {
    position: absolute;
    top: 0px;
    left: 550px;
}

.app-main-details-item.active::after {
    content: '';
    width: 212px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -1px;
    background: rgba(76, 172, 233, 1);
}

.app-main-details-item.inActive + div {
    display: none;
}

.app-main-details-item.active + div {
    display: block;
    height: 218px;
    background-color: antiquewhite;
}

.app-main-details-slide-wrapper {
    background-color: rgba(76, 172, 233, 1);
    border-radius: 10px;
    padding: 40px 0px 30px 40px;
}

.app-main-details-slide-header + div {
    display: flex;
}

.app-main-details-slide-content {
    border: 1px solid rgba(217, 217, 217, 1);
    background: rgba(235, 236, 235, 0.2);
    border-radius: 7px;
    height: 407px;
    padding: 35px 14% 0px;
    width: 100%;
}

.app-main-details-slide-content span {
    margin-left: 20px;
    font-size: 18px;
    line-height: 20px;
    justify-content: center;
}

.d1 .app-main-details-slide-content span {
    max-width: 126px;
}

.app-main-details-slide-content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 437px;
}

.app-main-details-slide-content > div:first-of-type {
    display: flex;
    align-items: center;
}

.app-main-details-slide-content > img {
    max-width: 309px;
    width: 70%;
}

.app-main-details-slide-content-wrapper + img {
    max-width: 650px;
    width: 70%;
}

.blackLine {
    width: 1px;
    background-color: rgb(46, 55, 60);
    height: 35px;
    margin-left: 16px;
}

.blueHeader {
    padding: 5px;
    background: rgba(76, 172, 233, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    max-width: 309px;
    width: 100%;
    text-align: center;
    line-height: 20px;
}

.app-main-details-slide-content > img,
.slick-slide:last-of-type .app-main-details-slide-content > div {
    max-width: 309px;
    width: 100%;
}

.d3 .blueHeader {
    padding: 5px 15px;
}

.app-main-details-slide-header {
    display: flex;
}

.app-main-details-slide-header img {
    margin-right: 15px;
}

.app-main-details-slide-header span {
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
}

@media all and (max-width: 1024px) {
    .app-main-details {
        padding: 56px 0px 111px 0px;
    }

    .app-main-details-wrapper {
        margin-top: 10px;
    }

    .app-main-details-slide-header span {
        font-size: 16px;
    }

    .app-main-details-slide-content > div > img {
        width: 50px;
    }

    .app-main-details-slide-content span {
        font-size: 12px;
        line-height: 14px;
        margin-left: 13px;
    }

    .blackLine {
        height: 24px;
    }

    .blueHeader {
        font-size: 12px;
        line-height: 20px;
    }

    .app-main-details-slide-content {
        padding: 15px 14% 0px;
        height: 314px;
        display: flex;
        flex-direction: column;
    }

    .d3 .app-main-details-slide-content img:last-of-type {
        max-width: 309px;
        width: 98%;
    }
}

@media all and (max-width: 768px) {
    .app-main-details {
        padding: 20px 0px 111px;
    }

    .app-main-details-slide-wrapper {
        height: 626px;
        padding: 27px 17px;
    }

    .app-main-details-slide-header + div {
        width: 100%;
    }

    .app-main-details-slide-header + div {
        flex-direction: column;
        align-items: center;
        max-width: 290px;
        margin: 10px auto;
    }

    .app-main-details-slide-content-wrapper + img {
        width: 100%;
    }

    .app-main-details-slide-header {
        max-width: 290px;
    }
}
