.app-main-panel {
    padding-bottom: 850px;
    padding-top: 83px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    width: 90%;
    max-width: 1648px;
    margin: 0 auto;
}

.app-main-panel-slide-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(103.61deg, #39A1E3 6.48%, #70CBFE 95.59%);
    border-radius: 10px;
    padding: 10px 0px;
    margin: 10px;
}

.app-main-panel .checkbox-img.active {
    top: 2px;
}

.app-main-panel .checkbox-wrapper {
    max-width: 1310px;
    padding: 0px;
}

.app-main-panel .checkbox-img > div > img {
    /*height: 666px;*/
    width: 60%;
    max-width: 432px;
}

.pan1 {
    max-width: 210px;
}

.main-picture {
    border-radius: 16px;
    box-shadow: 0px 3.74px 21.96px 0px rgba(22, 19, 52, 0.34);
    width: 134px;
}

.grid {
    background: linear-gradient(103.61deg, #39A1E3 6.48%, #70CBFE 95.59%);
    border-radius: 10px;
    display: grid;
    padding: 43px 4% 37px;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    grid-template-columns:1fr 1fr 1fr;
    width: 57%;
    grid-template-areas:"A A B"
    "C D E"
    "F F F"
    "G H I "
    "J J K "
    "L M N"
}

.grid-text-wrapper {
    background: rgba(247, 247, 247, 0.6);
    box-shadow: 0px 6.96px 16.52px 0px rgba(0, 45, 97, 0.25);
    font-size: 13px;
    display: flex;
    border-radius: 7px;
    padding: 9px 12px;
    align-items: center;
}

.grid-text-wrapper span {
    font-size: 13px;
    padding-left: 5px;
}

.grid-img-wrapper {
    display: flex;
    width: 100%;
    max-width: 208px;
    justify-content: space-between;
    align-items: flex-start;
}

.icon-picture {
    height: 42px;
    margin-top: 20px;
}

.icon-picture.small {
    height: 21px;
}

.g1 {
    grid-area: A;
}

.g2 {
    grid-area: B;
}

.g3 {
    grid-area: C;
}

.g4 {
    grid-area: D;
}

.g5 {
    grid-area: E;
    justify-content: center;
}

.g6 {
    grid-area: F;
}

.g7 {
    grid-area: G;
}

.g8 {
    grid-area: H;
}

.g9 {
    grid-area: I;
    justify-content: center;
}

.g10 {
    grid-area: J;
}

.g11 {
    grid-area: K;
}

.g12 {
    grid-area: L;
}

.g13 {
    grid-area: M;
}

.g14 {
    grid-area: N;
    justify-content: center;
}

.blueLine {
    height: 80px;
    background: rgba(15, 158, 225, 1);
    position: absolute;
    top: 18px;
    z-index: 0;
    width: 130%;
    transform: translateX(-50%);
    left: 50%;
}

.app-panel-main-img {
    position: relative;
    z-index: 1;
}

.text-wrapper {
    width: fit-content;
}

.app-panel-2, .app-panel-3 {
    width: fit-content;
    position: relative;
    margin: 0 auto;
}

.app-panel-2 .text-wrapper > div {
    width: 144px;
}

.app-panel-3 .text-wrapper > div {
    width: 164px;
}

.bottomRight {
    right: -2%;
    top: 68%;
}

.bottomRight > div {
    top: -10%;
    right: -91%;
}

.middleRight {
    top: 49%;
    right: -3%;
}

.middleRight > div {
    left: 93%;
    top: -10%;
}

.topRight {
    right: -5%;
    top: 23%;
}

.app-panel-2 .text-wrapper.topRight > div {
    width: 183px;
}

.topRight > div {
    left: 80%;
    top: 100%;
}

.middleLeft {
    top: 38%;
    left: -14%;
}

.middleLeft > div {
    top: 100%;
    right: 70%;
}

.app-panel-2 .text-wrapper.topLeft > div {
    width: 233px;
}

.topLeft {
    top: 21%;
    left: -10%;
}

.topLeft > div {
    top: 100%;
    right: 15%;
}

.bottomLeft {
    top: 76%;
    left: -9%;
}

.bottomLeft > div {
    right: 93%;
    top: -80%;
}

.pan3 .blueLine {
    height: 57px;
}

.left1 {
    top: 13%;
    left: -58%;
}

.left1 div:first-of-type {
    bottom: 30px;
}

.left1 div:last-of-type {
    top: 30px;
    left: -30%;
}

.left2 {
    top: 30%;
    left: -48%;
}

.left2 > div {
    bottom: 100%;
    left: -40%;
}

.left3 {
    top: 40%;
    left: -30%;
}

.left3.text-wrapper > div {
    width: 270px;
    top: -10%;
    right: 60%;
}

.left4 {
    top: 48%;
    left: -27%;
}

.left4 > div {
    top: 100%;
    right: 50%;
}

.left5 {
    top: 60%;
    left: -26%;
}

.left5 > div {
    left: -80%;
}

.left6 {
    top: 85%;
    left: -47%;
}

.left6 > div {
    bottom: 100%;
    left: -60%;
}

.right1 {
    top: 28%;
    right: -27%;
}

.right1 > div {
    left: 130px;
    bottom: 10px;
}

.right2 {
    top: 47%;
    right: -60%;
}

.right2 > div:first-of-type {
    top: 100%;
    left: 30%;
}

.right2 > div:last-of-type {
    right: -62%;
    bottom: -30px;
}

.right3 {
    top: 70%;
    left: 76%;
}

.right3 > div:first-of-type {
    bottom: -30px;
    left: 34%;
}

.right3 > div:last-of-type {
    right: -47%;
}

.right4 {
    top: 78%;
    left: 90%;
}

.right4 > div {
    top: 100%;
    left: 50%;
}

@media all and (max-width: 1400px) {
    .app-main-panel .checkbox-img > div > img {
        display: none;
    }

    .grid {
        width: 100%;
    }

    .blueLine {
        height: 43px;
    }

    .app-main-panel .checkbox-img > div {
        margin: 0 auto;
    }

    .app-panel-2 > img {
        height: 490px;
    }

    .app-panel-3 > img {
        height: 504px;
    }
}

@media all and (max-width: 1400px) {
    .app-main-panel {
        padding-bottom: 770px;
    }

    .app-main-panel .app-panel-2,
    .app-main-panel .app-panel-3
    {
        margin-top: 100px;
    }

    .topLeft {
        left: -18%;
    }

    .middleLeft {
        left: -18%;
    }

    .bottomLeft {
        top: 74%;
        left: -15%;
    }

    .bottomRight>div{
        right: -145px;
    }

    .left1 {
        top: 11%;
        left: -88%;
    }

    .left2 {
        top: 32%;
        left: -73%;
    }

    .left3 {
        top: 40%;
        left: -30%;
    }

    .left4 {
        top: 52%;
        left: -47%;
    }

    .left5 {
        top: 64%;
        left: -36%;
    }

    .left6 {
        top: 83%;
        left: -72%;
    }

    .right1 {
        top: 26%;
        right: -44%;
    }

    .right2 {
        top: 49%;
        right: -80%;
    }

    .right2 > div:last-of-type {
        right: -50%;
        bottom: 3px;
    }

    .right2 > div:first-of-type {
        top: 100%;
        left: 20%;
    }

    .right4 {
        top: 82%;
        left: 90%;
    }

    .right3 > div:first-of-type {
        bottom: -30px;
        left: 26%;
        width: 124px;
    }

    .right3 > div:last-of-type {
        right: -82%;
        top: 10px;
    }
}

@media all and (max-width: 1024px) {
    .pan1, .pan2, .pan3 {
        max-width: 90%;
        margin: 30px auto 0px;
    }

    .pan2-wrap, .pan3-wrap, .pan4-wrap, .pan5-wrap {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        height: 600px;
    }

    .pan2-wrap > img, .pan3-wrap > img,
    .pan4-wrap > img, .pan5-wrap > img {
        max-width: 190px;
        margin: 0 auto;
        padding-top: 40px;
    }

    .pan2-wrap > div, .pan3-wrap > div,
    .pan4-wrap > div, .pan5-wrap > div {
        position: absolute;
    }

    .checkbox-wrapper .pan2-wrap span,
    .checkbox-wrapper .pan3-wrap span,
    .checkbox-wrapper .pan4-wrap span,
    .checkbox-wrapper .pan5-wrap span {
        position: absolute;
        display: inline-block;
    }

    .p21-inn {
        top: 80px;
        left: -10px;
    }

    .p21-inn span {
        width: 210px;
        top: -70px;
        left: -20px;
    }

    .p22-inn {
        top: 350px;
    }

    .p22-inn span {
        width: 190px;
        left: 30px;
        top: 70px;
    }

    .p23-inn {
        top: 195px;
        left: -30px;
    }

    .p23-inn span {
        width: 210px;
        left: -20px;
    }

    .p24-inn {
        top: 80px;
        left: -20px;
    }

    .p24-inn span {
        width: 200px;
        top: -70px;
        left: -50px;
    }

    .p25-inn {
        top: 250px;
    }

    .p25-inn span {
        width: 170px;
        left: 30px;
        bottom: -20px;
    }

    .p26-inn {
        top: 310px;
        left: -35px;
    }

    .p26-inn span {
        width: 170px;
    }

    .p31-inn {
        top: 70px;
        left: -10px;
    }

    .p31-inn span {
        width: 150px;
        top: -40px;
        left: -30px;
    }

    .p32-inn {
        top: 70px;
        left: 20px;
    }

    .p32-inn span {
        top: -35px;
        left: 95px;
    }

    .p33-inn {
        top: 220px;
        left: 10px;
    }

    .p33-inn span {
        width: 100px;
        right: -80px;
    }

    .p34-inn {
        top: 360px;
        left: 70px;
    }

    .p34-inn span {
        width: 150px;
        top: -10px;
        left: 30px;
    }

    .p35-inn {
        top: 305px;
    }

    .p35-inn span {
        width: 200px;
        left: 30px;
        bottom: -20px;
    }

    .p36-inn {
        top: 170px;
        left: -30px;
    }

    .p36-inn span {
        left: -20px;
    }

    .p37-inn {
        top: 70px;
        left: -20px;
    }

    .p37-inn span {
        top: -50px;
        width: 150px;
        left: -50px;
    }

    .p38-inn {
        top: 70px;
        right: 20px;
    }

    .p38-inn span {
        top: -50px;
    }

    .p39-inn {
        top: 330px;
    }

    .p39-inn span {
        left: 35px;
        width: 190px;
        bottom: -30px;
    }

    .p390-inn {
        top: 350px;
        right: -10px;
    }

    .p390-inn span {
        bottom: -10px;
        left: -100px;
    }

    .icon-picture {
        height: 30px;
        margin-top: 0px;
    }

    .grid-img-wrapper, .grid-text-wrapper {
        width: 97%;
        max-width: 300px;
        margin-top: 5px;
    }

    .grid-text-wrapper, img {
        margin-bottom: 5px;
    }

    .main-picture {
        width: 107px;
    }

    .app-main-panel-slide-wrapper {
        height: 496px;
    }

    .app-main-panel {
        padding-bottom: 50px;
        padding-top: 23px;
    }

    .app-main-panel .checkbox-img.active {
        top: -72px;
    }
}

@media all and (max-width: 768px) {
    .pan1, .pan2, .pan3 {
        max-width: 90%;
        margin: 40px auto 0px;
    }
}