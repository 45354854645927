.app-main-youTube {
    background: linear-gradient(103.61deg, #39A1E3 6.48%, #70CBFE 95.59%);
    border-radius: 10px;
    /*padding: 130px 0 112px;*/
    margin: 0 auto 82px;
    width: 90%;
    max-width: 1461px;
    height: 762px;
}

.app-main-youTube h1 {
    font-size: 150px;
    line-height: 172px;
    color: white;
    position: relative;
    z-index: 1;
    padding-bottom: 176px;
}

.app-main-youTube a img {
    position: relative;
    z-index: 1;
}

.app-main-youTube h2 {
    font-size: 100px;
    color: white;
    line-height: 115px;
    position: relative;
    z-index: 1;
}

.app-main-youTube-wrapper>img {
    height: 661px;
    right: 0;
    top: -70px;
    position: absolute;
    z-index: 0;
}

.app-main-youTube-wrapper {
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.video-js {
    width: 100%;
    height: 100%;
}

@media all and (max-width: 1600px) {
    .app-main-youTube h2 {
        font-size: 5vw;
    }

    .app-main-youTube h1 {
        font-size: 9vw;
    }
}

@media all and (max-width: 1024px) {
    .app-main-youTube h2 {
        font-size: 22px;
        line-height: 26px;
    }

    .app-main-youTube h1 {
        font-size: 34px;
        line-height: 39px;
    }

    .app-main-youTube {
        /*padding: 23px 0 50px;*/
        margin: 0 auto 50px;
    }

    .app-main-youTube-wrapper>img {
        height: auto;
        top: -18px;
        right: -20px;
    }

    .app-main-youTube a img {
        width: 28px;
    }

    .app-main-youTube h1 {
        padding-bottom: 16px;
    }
}

@media all and (max-width: 768px) {
    .app-main-youTube {
        height: 262px;
    }
}