.slide-wrapper {
    display: flex;
    position: relative;
    right: 0;
    transition: 0.5s ease;
}

.sliderDotWrapper {
    display: flex;
    width: 155px;
    height: 60px;
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    justify-content: center;
    align-items: center;
}

.sliderBtnWrapper {
    display: flex;
    justify-content: center;
}

.videoPlayBtn {
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    background: #F5F5F5;
    cursor: pointer;
}
/* Polygon 1 */
.videoPlayBtn>div{
    /*background: #707072;*/
    border-radius: 2px;
    transform: rotate(89.83deg);
    border: 29px solid transparent; border-left: 29px solid #707072;
}

.sliderDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(112, 112, 114, 1);
}

.main-slider button{
    position: absolute;
    height: 42px;
    width: 41px;
    top: 138px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide-window {
    display: flex;
    overflow: hidden;
    border-radius: 8px;
}

.simple-slide {
    /*height: 472px;*/
    width: 100%;
}

.main-slider {
    /*position: absolute;*/
    right: 6%;
    top: 328px;
}

.slide-left {
    left: 38px;
}

.slide-right {
    left: 87px;
}

.sliderDotWrapper>div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-main-details-wrapper .slick-dots {
    display: flex;
}

.app-main .slick-dots li button:before {
    width: auto;
    height: 10px;
    content: '';
    background-color: rgba(112, 112, 114, 1);
    border-radius: 50%;
    opacity: 1;
}

.app-main .slick-dots li button {
    min-width: 10px;
    width: 10px;
    height: 10px;
    background-color: #707072;
    transition: min-width cubic-bezier(0.33, 0, 0.67, 1) 400ms, background-color linear 250ms;
    border-radius: 10px;
}

.app-main .slick-dots li.slick-active button {
    min-width: 52px;
    height: 10px;
    transition: min-width cubic-bezier(0.33,0,0.67,1) 400ms,background-color linear 250ms;
    border-radius: 10px;
}

.app-main .slick-dots li {
    width: auto;
    height: 10px;
}

.app-main .slick-dots li.slick-active {
    width: auto;
    height: 8px;
}

.video-slider-item {
    margin: 0 10px;
}

@keyframes slidein {
    0% {
        width: 0;
    }
    100% {
        width: 115%;
    }
}
